const initialState = {
    isLoggedIn: false,
}

const createNameSpacer = (nameSpace) => {
    return (action) => {
        return `${nameSpace}:${action}`;
    }
}

const getNameSpace = (actionType) => {
    return actionType.split(':')[0];
}

const nameSpace = 'SESSION'
const nameSpacer = createNameSpacer(nameSpace);

const createReducer = (initialState, handlers, nameSpaces) => {
    return (state=initialState, action) => {
        const nameSpace = getNameSpace(action.type);
        if(nameSpaces.indexOf(nameSpace) !== -1){
            return handlers[action.type](state, action);
        } else {
            return state;
        }
    }
}

export default (state=initialState, action) => {
    switch (action.type) {
        case nameSpacer("LOGIN"):
            return
        default:
            return state;
    }
}
