import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from '../../store';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from '../../routers/main';
import '@blueprintjs/core/lib/css/blueprint.css';

// TODO: Add Normalize.css

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <div className="App">
                    <MainRouter />
                </div>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
