import React from 'react';
import { Formik } from 'formik';
import { FormGroup, InputGroup, Intent, Button } from '@blueprintjs/core';
import * as Yup from 'yup';
import { connect } from 'react-redux';

const SignIn = (props) => {
    const _renderForm = ({handleSubmit, handleChange, handleBlur, values, errors, isSubmitting}) => {
        // TODO: Show loading on isSubmitting
        return <form onSubmit={handleSubmit}>
            <FormGroup
                label="username"
                labelFor="username"
                labelInfo="(required)"
                helperText={errors.username}
                intent={errors.username ? Intent.DANGER : null}
                >
                <InputGroup
                    id='username'
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    ></InputGroup>
            </FormGroup>
            <FormGroup
                label="password"
                labelFor="password"
                labelInfo="(required)"
                helperText={errors.password}
                intent={errors.password ? Intent.DANGER : null}
                >
                <InputGroup
                    id='password'
                    type='password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    ></InputGroup>
            </FormGroup>
            <Button type="submit">Login</Button>
        </form>
    }

    return <Formik
        onSubmit={(values) => {
            setTimeout(() => {
                props.setLoggedIn({
                    token: 'abcd'
                })
            }, 3000)
        }}
        validationSchema={Yup.object().shape({
            username: Yup.string().required('Username is required'),
            password: Yup.string().required('Password is required'),
        })}
        >
        {_renderForm}
    </Formik>
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoggedIn: (user) => {
            dispatch({
                // TODO: Create a namespacer
                type: `SESSION:SET_LOGGED_IN`,
                payload: {
                    value: user,
                }
            });
        }
    }
}

export default connect(null, mapDispatchToProps)(SignIn);