import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import LandingPage from '../components/LandingPage';
import { SignIn } from '../components/Auth';

const AnonymousRouter = withRouter((props) => {
    const { match } = props;
    return <Switch>
        <Route path={`/sign-in`} component={SignIn} exact/>
    </Switch>
})

const OutletRouter = (props) => {
    const { match } = props;
    return <Switch>
        <Route path={`${match.url}/dashboard`} render={() => {
            return <div>Outlet Dash</div>
        }}/>
    </Switch>
}

const StationRouter = (props) => {
    const { match } = props;
    return <Switch>
        <Route path={`${match.url}/dashboard`} render={() => {
            return <div>Station Dash</div>
        }}/>
    </Switch>
}

const AdminRouter = (props) => {
    const { match } = props;
    return <Switch>
        <Route path={`${match.url}/dashboard`} render={() => {
            return <div>Admin Dash</div>
        }}/>
    </Switch>
}

const AppRouter = (props) => {
    const { match } = props;
    return <Switch>
        <Route path={`${match.url}/admin`} component={AdminRouter}/>
        <Route path={`${match.url}/station`} component={StationRouter}/>
        <Route path={`${match.url}/station`} component={OutletRouter}/>
    </Switch>
}

const MainRouter = (props) => {
    return (
        <Switch>
            <Route path={`/`} component={LandingPage} exact/>
            {
                !props.isLoggedIn ? <AnonymousRouter/> : null
            }
            <Route path="/app" component={AppRouter}/>
        </Switch>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.session.isLoggedIn,
        role: state.session.role,
    }
}

export default connect(mapStateToProps, null)(MainRouter);