import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = (props) => {
    return <>
        <p>Landing Page</p>
        <Link to={`${props.match.url}sign-in`}>Login</Link>
    </>
}

export default LandingPage;